import { NgModule } from '@angular/core';
import { EllipsisDirective } from './directives/ellipsis.directive';

@NgModule({
  imports: [
  ],
  declarations: [EllipsisDirective],
  exports: [EllipsisDirective]
})
export class EllipsisModule { }
